import type { AssetBasicResponse } from "@/apis/services/AssetService";
import type {
  RatingHazardsCount,
  RatingInfo,
} from "@/apis/services/HazardService";
import {
  ReferenceTypeEnum,
  RiskRatingEnum,
} from "@/apis/services/HazardService";
import type {
  AssetBasicResponseFlattened,
  ScaledRiskRatingCount,
  ScalingMetricEnum,
} from "@/components/high-risks/types";
import { AREA_DISPLAY_LABEL } from "@/components/shared/user/user-settings";
import { AGGREGATE, ALL_CONSEQUENCES, ALL_HAZARDS } from "@/constants/risks";
import type { Flag } from "@/hooks/useFlags";
import { getFlagAttribute } from "@/utils/flags/flags-utils";

type CombinedAssets = AssetBasicResponseFlattened & RatingInfo;

export const getUniqueValuesFromObjectArray = <T>(
  key: string,
  assets: T[]
): string[] => {
  return [...new Set(assets.map((item: any) => item[key]))];
};

const selectFirstInAssetArrays = (
  asset: AssetBasicResponse
): AssetBasicResponseFlattened => {
  return {
    ...asset,
    asset_count: 1,
    ownership:
      asset.ownership && asset.ownership.length
        ? asset.ownership[0]
        : "Unassigned",
    business_group: asset.business_group ?? "Unassigned",
    primary_use:
      asset.primary_use && asset.primary_use.length
        ? asset.primary_use[0]
        : "Unassigned",
  };
};

export const combineAssetDataWithRiskData = (
  assets: AssetBasicResponse[],
  riskRatings: RatingInfo[]
): CombinedAssets[] => {
  return assets.map((o) =>
    Object.assign(
      selectFirstInAssetArrays(o),
      riskRatings?.find(
        (x) => x.ref_id === o.id && x.ref_type === ReferenceTypeEnum.ASSET
      ) ??
        ({
          risk_rating: RiskRatingEnum.NotAssessed,
          risk_sort_score: 0,
        } as RatingInfo)
    )
  );
};

export const scaleRiskRatingCount = (
  scalingMetric: ScalingMetricEnum,
  combinedAssetAndRiskratings: CombinedAssets[]
): ScaledRiskRatingCount => {
  const mappedRiskRatingCounts = {
    [RiskRatingEnum.Catastrophic]: 0,
    [RiskRatingEnum.VeryHigh]: 0,
    [RiskRatingEnum.HighVeryHigh]: 0,
    [RiskRatingEnum.High]: 0,
    [RiskRatingEnum.MediumHigh]: 0,
    [RiskRatingEnum.Medium]: 0,
    [RiskRatingEnum.LowMedium]: 0,
    [RiskRatingEnum.Low]: 0,
    [RiskRatingEnum.VeryLow]: 0,
    [RiskRatingEnum.Negligible]: 0,
    [RiskRatingEnum.NotAssessed]: 0,
    [RiskRatingEnum.Plausible]: 0,
  };

  combinedAssetAndRiskratings.forEach(
    (o) => (mappedRiskRatingCounts[o.risk_rating] += o[scalingMetric] ?? 0)
  );

  return mappedRiskRatingCounts;
};

export const combineAssetWithIrisGroups = (
  combinedAssetData: CombinedAssets[],
  irisGroupNamesPerAsset: string[][],
  groupName?: string
): CombinedAssets[] => {
  const irisGroupsPerAsset = combinedAssetData.map((asset, i) => {
    const irisGroupNames =
      irisGroupNamesPerAsset[i] && irisGroupNamesPerAsset[i].length
        ? irisGroupNamesPerAsset[i]
        : [groupName];

    return irisGroupNames.map((groupName) => ({
      ...asset,
      iris_group: groupName || "Unassigned",
    }));
  });

  return irisGroupsPerAsset.flat();
};

export const combineAssetWithTopHazards = (
  combinedAssets: CombinedAssets[],
  topHazards: RatingHazardsCount[]
): CombinedAssets[] => {
  const topAssets: CombinedAssets[] = [];

  topHazards.forEach((topHazard) => {
    Object.entries(topHazard.counts).forEach(([riskRating, assetIds]) => {
      const hazardAssets = combinedAssets
        .filter((asset) => assetIds.includes(asset.id))
        .map((asset) => ({
          ...asset,
          hazard: topHazard.hazard,
          risk_rating: riskRating as RiskRatingEnum,
        }));

      topAssets.push(...hazardAssets);
    });

    // Also add assets that don't have an assessment.
    const existingAssetSet = new Set(
      topAssets.map(
        (topHazardAsset) => `${topHazardAsset.id}-${topHazardAsset.hazard}`
      )
    );

    const notAssessedAssets = combinedAssets
      .filter(
        (asset) => !existingAssetSet.has(`${asset.id}-${topHazard.hazard}`)
      )
      .map((asset) => ({
        ...asset,
        hazard: topHazard.hazard,
        risk_rating: RiskRatingEnum.NotAssessed,
      }));

    topAssets.push(...notAssessedAssets);
  });

  return topAssets;
};

export const combineAssetsWithHighTide = (
  combinedAssets: CombinedAssets[],
  topHazards: RatingHazardsCount[],
  hightide: RatingInfo[]
) => {
  const topAssets: CombinedAssets[] = [];

  // assets that are not part of the hightide will not have their consequence set
  // and therefore be filtered out. Since we want to show all assets, including the
  // NotAssessed we add it manually.
  // TODO can we provide the consequence to the function to make it more reusable?
  const consequence = hightide?.[0]?.consequence;

  topHazards.forEach((topHazard) => {
    const hazard = topHazard.hazard;
    combinedAssets.forEach((asset) => {
      const id = asset.id;
      const rating = hightide.find(
        (r) => r.ref_id === id && r.hazard === hazard
      );

      topAssets.push({
        ...asset,
        consequence: consequence ?? null,
        hazard: topHazard.hazard,
        risk_rating: rating?.risk_rating ?? RiskRatingEnum.NotAssessed,
      });
    });
  });

  return topAssets;
};

export const getScalingUnit = (
  scalingMetric: ScalingMetricEnum,
  value?: number
): string => {
  switch (scalingMetric) {
    case "asset_count":
      return "-";
    case "occ_area":
      return AREA_DISPLAY_LABEL;
    case "total_building_population":
      return value && value === 1 ? "person" : `people`;
    case "gross_asset_value":
    case "replacement_cost":
    case "monthly_revenue":
      return `$`;
    default:
      return "";
  }
};

export const calculatePaddingForBarWidth = (
  chartWidth: number,
  barCount: number,
  desiredBarWidth: number
) => {
  return 1 - ((barCount + 1) * desiredBarWidth) / chartWidth;
};

export const filterByHazard = <T>(data: T[], hazard?: string[]) => {
  if (!hazard || hazard.length === 0) return data;
  if (isHazardAggregate(hazard)) return data;

  return data.filter((o: any) => o.hazard && hazard.includes(o.hazard));
};

export const filterByConsequence = <T>(data: T[], consequence?: string[]) => {
  if (!consequence || consequence.length === 0) return data;
  if (isConsequenceAggregate(consequence)) return data;

  return data.filter(
    (o: any) => o.consequence && consequence.includes(o.consequence)
  );
};

export const isMultiHazardSelected = (value?: string[]) => {
  if (!value || value.length === 0) return false;
  if (value.includes(AGGREGATE)) return true;
  return false;
};

export const isHazardAggregate = (value?: string[]) => {
  if (!value || value.length === 0) return false;

  if (value.includes(AGGREGATE)) return true;
  if (value.includes(ALL_HAZARDS)) return true;
  return false;
};

export const isConsequenceAggregate = (value?: string[]) => {
  if (!value || value.length === 0) return false;

  if (value.includes(ALL_CONSEQUENCES)) return true;
  return false;
};

export const getDefaultHazard = (flags: Flag[], byHazard?: boolean) => {
  const label = byHazard ? "hazard_hazards_value" : "group_hazard_value";
  const attribute = getFlagAttribute(flags, "RiskExposureModule", label);
  return attribute ?? (byHazard ? ALL_HAZARDS : AGGREGATE);
};

export const getDefaultConsequence = (flags: Flag[], byHazard?: boolean) => {
  const label = `${byHazard ? "hazard_" : "group_"}consequence_value`;
  const attribute = getFlagAttribute(flags, "RiskExposureModule", label);
  return attribute ?? ALL_CONSEQUENCES;
};
